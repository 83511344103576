import React from 'react';
import { Button, Form, Input, Layout, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../API';

const { Header, Content, Footer } = Layout;

const Register = () => {
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    console.log('Datos enviados:', values); // Verifica qué datos estás enviando

    try {
      // Enviar los datos a la API para crear un nuevo usuario
      const response = await axios.post(`${API_URL}users/`, values);

      if (response.status === 201) {
        message.success('Usuario registrado exitosamente');
        navigate('/');
      }
    } catch (error) {
      console.error('Error al registrar usuario:', error);
      if (error.response && error.response.data) {
        // Verifica si el error es debido a un correo electrónico ya registrado
        if (error.response.data.email) {
          message.error('El correo electrónico ya está registrado. Por favor, elija otro.');
        } else {
          // Muestra el detalle del error que devuelve el servidor
          message.error(`Error: ${JSON.stringify(error.response.data)}`);
        }
      } else {
        message.error('Error en la conexión con el servidor');
      }
    }
  };

  return (
    <Layout style={{ height: '100vh' }}>
      <Header style={{ color: 'white', textAlign: 'center', fontSize: '24px' }}>
        Registrarse
      </Header>
      <Content
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Form
          name="register"
          layout="vertical"
          style={{ width: '300px' }}
          onFinish={handleSubmit}
        >
          <Form.Item
            label="Nombre de Pila"
            name="nombrepila"
            rules={[{ required: true, message: 'Por favor ingrese su nombre de pila!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Apellido Paterno"
            name="appat"
            rules={[{ required: true, message: 'Por favor ingrese su apellido paterno!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Apellido Materno"
            name="apmat"
            rules={[{ required: true, message: 'Por favor ingrese su apellido materno!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Correo Electrónico"
            name="email"
            rules={[{ required: true, message: 'Por favor ingrese su correo electrónico!' }]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item
            label="Contraseña"
            name="password"
            rules={[{ required: true, message: 'Por favor ingrese su contraseña!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="is_staff"
            initialValue={false}
            hidden
          >
            <Input type="hidden" />
          </Form.Item>

          <Form.Item
            name="is_superuser"
            initialValue={false}
            hidden
          >
            <Input type="hidden" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Registrarse
            </Button>
          </Form.Item>
        </Form>
      </Content>
      <Footer style={{ textAlign: 'center' }}>©2023 Pprody</Footer>
    </Layout>
  );
};

export default Register;
