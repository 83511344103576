import React from 'react';
import { useSpring, animated } from 'react-spring';

const Flower = ({ index }) => {
  const props = useSpring({
    from: { opacity: 0, transform: `translateY(${Math.random() * 50}px)` },
    to: { opacity: 1, transform: `translateY(${Math.random() * 100}px)` },
    reset: true,
    reverse: true,
    config: { duration: 1500 },
    loop: true,
    delay: index * 200,
  });

  return (
    <animated.div
      style={{
        ...props,
        position: 'absolute',
        fontSize: `${Math.random() * 30 + 20}px`,
        left: `${Math.random() * 100}vw`,
        top: `${Math.random() * 100}vh`,
      }}
    >
      🌼
    </animated.div>
  );
};

export default Flower;
