import React from 'react';
import { Layout, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Header, Content, Footer } = Layout;

const AdminDashboard = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Lee los tokens antes de eliminarlos
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');

    // Imprime los tokens en la consola
    console.log('Tokens a eliminar:');
    console.log('Access Token:', accessToken);
    console.log('Refresh Token:', refreshToken);

    // Elimina los tokens del localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    // Imprime un mensaje confirmando la eliminación
    console.log('Usuario desconectado: Tokens eliminados.');

    // Redirige a la página de inicio de sesión
    navigate('/');
  };

  return (
    <Layout style={{ height: '100vh' }}>
      <Header style={{ color: 'white', textAlign: 'center', fontSize: '24px' }}>
        Dashboard de Administrador
      </Header>
      <Content
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <h1>Bienvenido, Administrador</h1>
        <Button type="primary" onClick={handleLogout}>
          Cerrar Sesión
        </Button>
      </Content>
      <Footer style={{ textAlign: 'center' }}>©2023 Mi Proyecto</Footer>
    </Layout>
  );
};

export default AdminDashboard;
