import React from 'react';
import Flower from './Flower';
import { useNavigate } from 'react-router-dom'; // Asegúrate de importar useNavigate
import './FlowerAnimation.css'; // Asegúrate de tener este archivo CSS

const FlowerAnimation = () => {
  const numFlowers = 100; // Número de flores a mostrar
  const navigate = useNavigate(); // Usar navigate para redirigir

  const handleUserClick = () => {
    navigate('/user-dashboard'); // Navega a la página del dashboard del usuario
  };

  return (
    <div className="flower-container">
      {Array.from({ length: numFlowers }).map((_, index) => (
        <Flower key={index} index={index} />
      ))}
      
      {/* Agregar botón para redirigir */}
      <button onClick={handleUserClick} className="navigate-button">
        Ir al Dashboard
      </button>
    </div>
  );
};

export default FlowerAnimation;
