import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Welcome from './components/Welcome';
import Login from './components/Login';
import Register from './components/Register'; // Importa el nuevo componente
import AdminDashboard from './components/AdminDashboard';
import UserDashboard from './components/UserDashboard';
import FlowerAnimation from './components/FlowerAnimation';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} /> {/* Nueva ruta */}
        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/user-dashboard" element={<UserDashboard />} />
        <Route path="/flower-animation" element={<FlowerAnimation />} />
      </Routes>
    </Router>
  );
};

export default App;
