import React from 'react';
import { Button, Form, Input, Layout, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../API';

const { Header, Content, Footer } = Layout;

const Login = () => {
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
            const response = await axios.post(`${API_URL}login/`, values);

      if (response.status === 200) {
        const { message: loginMessage, access, refresh } = response.data;

        // Log tokens to the console
        console.log('Access Token:', access);
        console.log('Refresh Token:', refresh);

        // Almacenar tokens en el localStorage o en una cookie según prefieras
        localStorage.setItem('access_token', access);
        localStorage.setItem('refresh_token', refresh);

        // Muestra un mensaje basado en el tipo de usuario
        message.success(loginMessage);

        // Redirigir según el tipo de usuario
        if (loginMessage.includes('administrador')) {
          navigate('/admin-dashboard');
        } else {
          navigate('/user-dashboard');
        }
      }
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      if (error.response && error.response.data) {
        message.error(error.response.data.detail);
      } else {
        message.error('Error en la conexión con el servidor');
      }
    }
  };

  return (
    <Layout style={{ height: '100vh' }}>
      <Header style={{ color: 'white', textAlign: 'center', fontSize: '24px' }}>
        Iniciar Sesión
      </Header>
      <Content
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Form
          name="login"
          layout="vertical"
          style={{ width: '300px' }}
          onFinish={handleSubmit}
        >
          <Form.Item
            label="Correo Electrónico"
            name="email"
            rules={[{ required: true, message: 'Por favor ingrese su correo electrónico!' }]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item
            label="Contraseña"
            name="password"
            rules={[{ required: true, message: 'Por favor ingrese su contraseña!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Iniciar Sesión
            </Button>
          </Form.Item>
        </Form>
      </Content>
      <Footer style={{ textAlign: 'center' }}>©2023 Pprody</Footer>
    </Layout>
  );
};

export default Login;
