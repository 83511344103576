import React from 'react';
import { Button, Layout } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Header, Content, Footer } = Layout;

const Welcome = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login'); // Navega a la página de inicio de sesión
  };

  const handleRegisterClick = () => {
    navigate('/register'); // Navega a la página de registro
  };

  return (
    <Layout style={{ height: '100vh' }}>
      <Header style={{ color: 'white', textAlign: 'center', fontSize: '24px' }}>
        ¡Bienvenido a mi Proyecto!
      </Header>
      <Content
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <h1>Página de Bienvenida</h1>
        <Button type="primary" size="large" onClick={handleLoginClick}>
          Iniciar Sesión
        </Button>
        <Button
          style={{ marginTop: '20px' }}
          type="default"
          size="large"
          onClick={handleRegisterClick}
        >
          Registrarse
        </Button>
      </Content>
      <Footer style={{ textAlign: 'center' }}>©2024 Pprody</Footer>
    </Layout>
  );
};

export default Welcome;
